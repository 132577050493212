import React from 'react';

import {
  FooterContainer,
  FooterContent,
  MethodologyText,
  MightyLink,
} from './Footer.styles';

export const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <MethodologyText>
                    <p>
                        Methodology: Where available and public, we looked at platform fees. We chose the base level of subscription or the average, depending on the available data.
                    </p>
                    <p>
                        Where creators earn revenue based on views or through brand deals based on follower count, we estimated amounts based on publicly shared averages per number of followers or per 1,000 views.
                    </p>
                </MethodologyText>
                <MightyLink>
                    by <a href="https://mightynetworks.com?utm_medium=referral&utm_source=calculator&utm_content=footer" target="_blank" rel="noopener noreferrer">Mighty Networks</a>
                </MightyLink>
            </FooterContent>
        </FooterContainer>
    )
}
