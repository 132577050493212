import styled from 'styled-components';

import { layout } from '../styles/layout';

export const HeaderContainer = styled.section`
    display: grid;
    justify-self: center;
    max-width: 720px;
    text-align: center;
    margin: 0 auto 3.1rem;
    padding: 0 1rem;

    @media screen and (max-width: ${layout.sm}) {
        margin: 0 auto 0.4rem;
        padding: 0 1.5rem;
    }

    h1 {
        font-weight: bold;
        line-height: 4.8rem;
        font-size: 4rem;

        @media screen and (max-width: ${layout.subMd}) {
            font-size: 3.1rem;
            line-height: 3.8rem;
        }

        @media screen and (max-width: ${layout.xs}) {
            font-size: 2.5rem;
            line-height: 2.6rem;
        }        
    }
`
