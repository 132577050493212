import React, { useMemo } from 'react';
import ReactGA from 'react-ga4'

import {
    PlatformCardContainer,
    PlatformCardTitle,
    PlatformCardContent,
    ExplainText
} from './PlatformCard.styles';

export const PlatformCard = ({ platform, baseMoney }) => {
    const logos = require.context('../../assets/Logos', true);
    const sliderAmount = useMemo(() => {
        if (!baseMoney) return '0'

        return new Intl.NumberFormat().format(Math.floor(baseMoney / platform.divider));
    }, [ baseMoney, platform.divider ])

    return (
        <PlatformCardContainer
            href={platform.link}
            target="_blank"
            rel="noopener noreferrer"
            backgroundColor={platform.background}
            onClickCapture={() => ReactGA.event({
                category: 'User Engagement',
                action: 'Link Clicks',
                label: platform.name
            })}
        >
            <PlatformCardTitle>
                <img src={logos(`./${platform.logo}`)} alt={platform.name}/>
                <p>{ platform.name }</p>
            </PlatformCardTitle>
            <PlatformCardContent>
                <h1>{sliderAmount} {platform.metric}</h1>
                <ExplainText>{platform.text}</ExplainText>
            </PlatformCardContent>
        </PlatformCardContainer>
    )
}
