import styled from 'styled-components';
import { layout } from './styles/layout';

export const AppContainer = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 1140px;
    box-sizing: border-box;
    padding: 8.75rem 0 7rem;

    @media screen and (max-width: ${layout.sm}) {
        padding: 2.5rem 0 3.3rem;
    }
`;
