import styled from 'styled-components';
import { layout } from '../styles/layout';

export const FooterContainer = styled.section`
    background-color: #000000;
    width: 100%;

    padding: 3rem 12rem;

    @media screen and (max-width: ${layout.lg}) {
        padding: 2.4rem 6rem;
    }

    @media screen and (max-width: ${layout.subMd}) {
        padding: 2.3rem 2rem;
    }    

    @media screen and (max-width: ${layout.xxs}) {
        padding: 2.3rem 1rem;
    }    
`

export const FooterContent = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-weight: 400;
    align-items: center;
`

export const MethodologyText = styled.div`
    max-width: 420px;
    font-size: 0.7rem;

    p:last-child {
        margin: 0;
    }
`

export const MightyLink = styled.div`
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;

    @media screen and (max-width: ${layout.sm}) {
        margin-left: 1.2rem;
        min-width: 120px;
        align-self: flex-end;
    }    

    a {
        color: #ffffff;
        text-decoration: underline;
        transition: opacity 300ms ease-in-out;

        &:hover {
           opacity: 0.8;
        }
    }
`
