import React from 'react';

import { HeaderContainer } from  './Header.styles';

export const Header = () => {
  return (
    <HeaderContainer>
        <h1>How Much Can Creators Earn Monthly?</h1>
    </HeaderContainer>
  );
};
