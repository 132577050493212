export const layout = {
  xlg: '1400px',
  lg: '1100px',
  subLg: '950px',
  md: '900px',
  subMd: '800px',
  sm: '700px',
  xs: '500px',
  xxs: '420px',
}
