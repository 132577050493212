import styled from 'styled-components';
import { layout } from '../../styles/layout';

export const PlatformCardContainer = styled.a`
    margin: 0.75rem;
    background: ${({ backgroundColor }) => backgroundColor};
    color: #ffffff;
    cursor: pointer;
    transition: box-shadow 300ms ease-in-out;

    @media screen and (max-width: ${layout.sm}) {
        margin: 0.8rem 1.25rem;
    }

    &:hover {
        text-decoration: none;
        color: #ffffff;
        box-shadow: 0 1px 30px rgb(0 0 0 / 43%), 0 0 1px rgb(0 0 0 / 5%);
    }   
`

export const PlatformCardTitle = styled.section`
    padding: 1.1rem 1.7rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    display: flex;

    img {
        max-height: 25px;
        margin-right: 0.7rem;
    }
    
    p {
        font-size: 1.1rem;
        margin-bottom: 0;
    }
`

export const PlatformCardContent = styled.section`
    padding: 1.25rem 2rem 1.6rem;
    min-height: 120px;
    position: relative;

    h1 {
        font-weight: bold;
        font-size: 1.67rem;
        line-height: 1.6rem;
        margin-bottom: 1.5rem;
    }
`

export const ExplainText = styled.span`
    position: absolute;
    top: 4.7rem;
    right: 1.2rem;
    left: 1.9rem;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1rem;
`
