import styled from 'styled-components';
import { layout } from '../../styles/layout';

export const PlatformListComponent = styled.section`
    width: 100%;   
`

export const PlatformListContent = styled.div`
    width: 100%;
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: ${layout.subLg}) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: ${layout.sm}) {
        grid-template-columns: 1fr;
    }    
`;
