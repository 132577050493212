import React from 'react';

import { PlatformCard } from './PlatformCard';
import {
  PlatformListComponent,
  PlatformListContent
} from './PlatformList.styles';

export const PlatformList = ({ platforms, baseMoney }) => {
    return (
        <PlatformListComponent>
            <PlatformListContent>
                {
                    platforms.map((platform, index) => (
                        <PlatformCard platform={platform} key={index} baseMoney={baseMoney} />
                    ))
                }                                                                                             
            </PlatformListContent>
        </PlatformListComponent>
    )
}
