import styled, { css } from 'styled-components';
import { layout } from '../styles/layout';

export const MoneySilerContainer = styled.section`
    position: relative;
    max-width: 850px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto 3.4rem;
    box-sizing: border-box;
    padding: 1.5rem 1.4rem;
    opacity: 1;

    @media screen and (max-width: ${layout.sm}) {
        margin: 0 auto 1rem;
    }

    ${({ hide }) => !hide ? '' : css`
        opacity: 0;
    `}
`

export const MoneySliderStickyContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 1.1rem;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    margin: 0;
    background-color: #ffffff;
    box-shadow: 0px 27px 19px rgb(0 0 0 / 13%);
`

export const SliderContainer = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    outline: none;

    input[type="range"]::-webkit-slider-runnable-track {
        background-color: #000000;
    }

    input[type="range"]::-webkit-slider-thumb {
        background: #000000 !important;
    }

    input[type="range"]::-moz-range-progress {
        background-color: #000000;
    }

    input[type="range"]::-moz-range-thumb {
        background-color: #000000 !important;
    }

    input[type="range"]::-moz-range-track {  
        background-color: #000000;
    }
`

export const SelectedMoney = styled.h1`
    font-weight: bold;
    font-size: 3.1rem;
    margin-right: 3.4rem;
    margin-bottom: 0%;
    text-align: right;
    min-width: 185px;

    @-moz-document url-prefix() {
        min-width: 235px;
    }

    @media screen and (max-width: ${layout.subLg}) {
        min-width: 165px;        
        font-size: 2.4rem;
        margin-right: 1.6rem;
    }

    @media screen and (max-width: ${layout.sm}) {
        min-width: 115px;        
        font-size: 1.8rem;
        margin-right: 1.4rem;
        text-align: center;
    }    
`
