import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';

import {
  MoneySilerContainer,
  SliderContainer,
  SelectedMoney,
  MoneySliderStickyContainer
} from './MoneySlider.styles';

const STEP_VALUE = 500;
const MINIMAL_VALUE = 500;
const MAXIMUM_VALUE = 100000;
const ARROW_KEY_NAMES = ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];

export const MoneySlider = ({ money, setMoney }) => {
    const [ isSticky, setIsSticky ] = useState(false);
    const sliderRef = useRef(null);

    const formattedMoney = useMemo(() => new Intl.NumberFormat().format(money), [ money ])

    const handlePageScroll = useCallback(() => {
        if (sliderRef.current && window) {
            const isMobile = window.innerWidth <= 950;
            const { top } = sliderRef.current.getBoundingClientRect()

            if (top < 0 && isMobile) {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }
        }
    }, [])

    useEffect(() => {
        handlePageScroll();
        window.addEventListener('scroll', handlePageScroll);

        return () => {
            window.removeEventListener('scroll', handlePageScroll);
        }
    }, [ handlePageScroll ])

    const handleDecreaseValue = useCallback(() => {
        if (money > MINIMAL_VALUE) {
            setMoney(parseFloat(money) - STEP_VALUE)
        }
    }, [ money, setMoney ])

    const handleIncreaseValue = useCallback(() => {
        if (money < MAXIMUM_VALUE) {
            setMoney(parseFloat(money) + STEP_VALUE)
        }
    }, [ money, setMoney ])

    const handleKeyDown = useCallback((event) => {
        if (ARROW_KEY_NAMES.includes(event.key)) {
            event.stopPropagation();
            event.preventDefault();
            switch (event.key) {
                case 'ArrowUp':
                case 'ArrowRight':
                    handleIncreaseValue();
                    break;
                case 'ArrowLeft':
                case 'ArrowDown':
                    handleDecreaseValue();
                    break;
                default:
                    break;
            }
        }
    }, [ handleDecreaseValue, handleIncreaseValue ]);

    const renderMoneySliderContent = () => (
        <React.Fragment>
            <SelectedMoney>${formattedMoney}</SelectedMoney>
            <SliderContainer tabIndex={-1} onKeyDown={handleKeyDown}>
                <RangeSlider
                    value={money}
                    step={STEP_VALUE}
                    onChange={e => setMoney(e.target.value)}
                    min={MINIMAL_VALUE}
                    max={MAXIMUM_VALUE}
                    variant="dark"
                />
            </SliderContainer>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <MoneySilerContainer ref={sliderRef} hide={isSticky}>
                {renderMoneySliderContent()}
            </MoneySilerContainer>
            {
                isSticky && (
                    <MoneySliderStickyContainer>
                        {renderMoneySliderContent()}
                    </MoneySliderStickyContainer>
                )
            }
        </React.Fragment>
    )
}
