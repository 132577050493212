import React, {useEffect, useState} from 'react';
import { AppContainer } from './App.styles';
import { Header } from './components/Header';
import { MoneySlider } from './components/MoneySlider';
import { Footer } from './components/Footer';
import { PlatformList } from './components/Platforms/PlatformList';
import * as data from './database.json';
import ReactGA from 'react-ga4';

function App() {
  const [ money, setMoney ] = useState(500);
  const platforms = data.default;

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: 'UA-210909861-1'
      },
      {
        trackingId: 'G-FGY5S3K9D0'
      }
    ])
    ReactGA.send('pageview')
  }, [])

  return (
    <>
      <AppContainer>
        <Header />
        <MoneySlider money={money} setMoney={setMoney} />
        <PlatformList platforms={platforms} baseMoney={money} />
      </AppContainer>

      <Footer />
    </>
  );
}

export default App;
